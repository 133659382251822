// import React from "react"
import * as style from './src/styles/global.scss'

export const onRouteUpdate = ({ location, prevLocation }) => {
  console.log("new pathname", location.pathname)
  console.log("old pathname", prevLocation ? prevLocation.pathname : null)
}

// export const wrapPageElement = ({ element, props }) => {
//   return (
//     <div {...props}>
//       {element}
//       <div>global</div>
//     </div>
//   )
// }
